import { useFetch } from "nuxt/app";
import type { FetchOptions } from "ofetch";
import type { UseFetchOptions } from "nuxt/app";
import { useAuthStore } from "~/store/auth";
import { getBrowserInfo } from "~/utils/clientInfo";
import { storage } from "~/utils/consts";
import Cookie, { MAX_AGE_YEAR } from "~/utils/cookie";
import { getJWTExpiration, isJWTExpired } from "~/utils/jwt";
import type { IJWT } from "~/utils/jwt";
import getCookieDomain from "~/utils/getCookieDomain";

interface IRequestParams<T> extends UseFetchOptions<T> {
  gateway?: "web" | "mock" | "magnit-id";
  permissions?: {
    jwt?: boolean;
  };
  headers?: FetchOptions["headers"];
}

export const getHeaders = (headers: FetchOptions["headers"], version: string): HeadersInit => {
  const ksid = Cookie.get(storage.ksid);
  let userAgent = "";
  let platformVersion = "omit empty";
  const deviceId = useCookie(storage.uniqDeviceID).value || "omit empty";

  if (import.meta.client) {
    userAgent = window.navigator.userAgent;

    const browserInfo = getBrowserInfo();
    platformVersion = `${browserInfo.os} ${browserInfo.browser} ${browserInfo.version}`;
  } else {
    const reqHeaders = useRequestHeaders();
    userAgent = reqHeaders["user-agent"] || "";
  }

  return {
    ...toValue(headers),
    "Content-Type": "application/json",
    "User-Agent": userAgent,
    "X-Platform-Version": platformVersion,
    "X-Device-Tag": ksid || "disabled",
    "X-Device-ID": deviceId,
    "X-App-Version": version || "",
    "X-Device-Platform": "Web",
  };
};

const getJWT = async (): Promise<IJWT | null> => {
  if (typeof window === "undefined") {
    return null;
  }
  const { cookieDomain: configCookieDomain } = useRuntimeConfig().public;
  const { hostname } = useRequestURL();
  const cookieDomain = configCookieDomain || getCookieDomain(hostname);
  const cookieJWT = useCookie<IJWT | null | undefined>(storage.jwt, {
    domain: cookieDomain,
    maxAge: MAX_AGE_YEAR,
  });

  if (!cookieJWT.value) {
    return null;
  }

  const expiration = getJWTExpiration(cookieJWT.value.access);

  if (expiration === null) {
    throw new Error("Invalid token");
  }

  if (isJWTExpired(expiration)) {
    const authStore = useAuthStore();
    await authStore.refresh();
  }

  return cookieJWT.value;
};

const getPermissionJWT = async (): Promise<string> => {
  const JWT = await getJWT();

  if (!JWT) {
    throw new Error("Отсутствует JWT токен");
  }

  return `Bearer ${JWT.access}`;
};

export const useTransport = async <R = void>(url: string, params?: IRequestParams<R>) => {
  const config = useRuntimeConfig();

  const { permissions, gateway = "web", headers, ...restParams } = params || {};

  let _headers = getHeaders(headers, config.public.version as string);

  if (gateway === "web") {
    _headers = {
      ..._headers,
      "X-Client-Name": "mymagnit",
    };
  }

  if (permissions?.jwt) {
    const Authorization = await getPermissionJWT();

    _headers = {
      ..._headers,
      Authorization,
    };
  }

  return useFetch(url, {
    baseURL: gateway === "web" ? "/webgate" : gateway === "mock" ? "/mock" : "/magnit-id",
    headers: _headers,
    ...restParams,
  });
};
