import type { FetchError } from "ofetch";
import { defineStore } from "pinia";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useLocalStorage } from "@vueuse/core";
import { useTransport, getHeaders } from "~/composables/useTransport";
import { HttpCodes, urls } from "~/api/config";
import { storage } from "~/utils/consts";
import useAnalytics from "~/composables/useAnalytics";
import useNotification from "~/composables/useNotification";
import usePageLoader from "~/composables/usePageLoader";
import Cookie, { MAX_AGE_YEAR } from "~/utils/cookie";
import { getJWTExpiration, type IJWT } from "~/utils/jwt";
import { Routes } from "~/utils/routes";
import getCookieDomain from "~/utils/getCookieDomain";

interface IAuthState {
  authorized: boolean;
  registered: boolean;
  tokenValue: null | string;
  tokenExpires: null | number;
}

interface IAuthStatus {
  login: "initial" | "pending" | "success" | "error";
}

export const useAuthStore = defineStore("auth", () => {
  const config = useRuntimeConfig();
  const router = useRouter();
  const { cookieDomain: configCookieDomain } = useRuntimeConfig().public;
  const { error: showToastError } = useNotification();
  const { hide: hidePageLoader, show: showPageLoader } = usePageLoader();
  const { send } = useAnalytics();
  const { hostname } = useRequestURL();
  const loyaltyCache = useLocalStorage(storage.bonuses, "");
  const cookieDomain = configCookieDomain || getCookieDomain(hostname);
  const cookieJWT = useCookie<IJWT | null | undefined>(storage.jwt, {
    domain: cookieDomain,
    maxAge: MAX_AGE_YEAR,
  });

  let refreshPromise: Promise<void> | null = null;

  const state = reactive<IAuthState>({
    authorized: false,
    registered: false,
    tokenValue: null,
    tokenExpires: null,
  });

  const status = reactive<IAuthStatus>({
    login: "initial",
  });

  const getMagnitId = () => Cookie.get(storage.magnitIDCode) || "";
  const setMagnitId = (code: string) => {
    Cookie.set(storage.magnitIDCode, code, { "max-age": 3600 });
  };

  const getRegister = () => state.registered;
  const setRegister = (value: boolean) => {
    state.registered = value;
  };

  const setTokens = (access: string, refresh: string) => {
    state.tokenValue = access;
    state.tokenExpires = getJWTExpiration(access);
    cookieJWT.value = { access, refresh };
    setAuth(true);
  };

  const setAuth = (value: boolean) => {
    state.authorized = value;
  };

  const clearCurrentTokenState = () => {
    state.tokenValue = null;
    state.tokenExpires = null;
  };

  async function logout() {
    status.login = "initial";

    setAuth(false);
    clearCurrentTokenState();

    cookieJWT.value = null;
    Cookie.delete(storage.ksid);

    loyaltyCache.value = "";

    await router.push(Routes.Login);
  }

  async function login() {
    showPageLoader();

    const { data, error } = await useTransport<{
      accessToken: string;
      refreshToken: string;
    }>(urls.auth.login, {
      gateway: "magnit-id",
      method: "POST",
      body: {
        aud: "loyalty-web",
        magnitIDCode: getMagnitId(),
      },
    });

    if (data.value) {
      setMagnitId("");
      setTokens(data.value.accessToken, data.value.refreshToken);

      status.login = "success";
      hidePageLoader();
    }

    if (
      error.value?.statusCode &&
      error.value.statusCode >= HttpCodes.Error4xx
    ) {
      if (error.value.data.code) {
        send("LoginModal:Validation:Error", {
          type: getRegister() ? "registration" : "login",
          error_type: error.value.data.code,
          text: error.value.data.message,
        });
      }

      await router.push(Routes.Login);

      status.login = "initial";
      hidePageLoader();
      showToastError();
    }
  }

  const checkLogin = async () => {
    let loggedIn = false;
    if (cookieJWT.value?.access) {
      const expiration = state.tokenExpires;
      const isExpired = expiration ? isJWTExpired(expiration) : true;
      if (isExpired) {
        try {
          await refresh();
          loggedIn = true;
        } catch {
          loggedIn = false;
        }
      } else {
        loggedIn = true;
      }
      setAuth(loggedIn);
    }
    return loggedIn;
  };

  async function refresh() {
    if (!refreshPromise) {
      refreshPromise = new Promise((resolve, reject) => {
        $fetch<{
          accessToken: string;
          refreshToken: string;
        }>(urls.auth.refresh, {
          baseURL: "/magnit-id",
          headers: getHeaders({}, config.public.version as string),
          method: "POST",
          body: {
            aud: "loyalty-web",
            refreshToken: cookieJWT.value?.refresh,
          },
        }).then(
          (data) => {
            if (data.accessToken) {
              setTokens(data.accessToken, data.refreshToken);
              resolve();
            }
          },
          async (error: FetchError) => {
            if (error?.statusCode) {
              if (error.statusCode === HttpCodes.Unauthorized) {
                await logout();
                return;
              }

              if (error.statusCode >= HttpCodes.Error4xx) {
                status.login = "initial";
                showToastError();
                await logout();
                reject(new Error("refresh request error"));
              }
            }
          },
        ).catch(async () => {
          await logout();
        });
      });
    }

    return refreshPromise.finally(() => {
      refreshPromise = null;
    });
  }

  async function deactivate() {
    await refresh();
    const { data, error } = await useTransport<{
      deactivated: boolean;
    }>(urls.user.deactivate, {
      gateway: "magnit-id",
      method: "POST",
      permissions: { jwt: true },
    });

    if (data.value) {
      return data.value;
    } else {
      throw error.value;
    }
  }

  return {
    getMagnitId,
    setMagnitId,
    getRegister,
    setRegister,
    setAuth,
    login,
    logout,
    checkLogin,
    refresh,
    deactivate,
    state,
    status,
  };
});
