export interface IJWT {
  access: string;
  refresh: string;
}

export const isJWTExpired = (expiration: number): boolean =>
  Date.now() > expiration;

export const getJWTExpiration = (token: string | null): number | null => {
  if (typeof token !== "string" || !token) {
    return null;
  }

  try {
    const { exp } = JSON.parse(atob(token.split(".")[1]));
    return exp * 1000;
  } catch {
    return null;
  }
};
