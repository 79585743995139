enum HttpCodes {
  Success = 200,
  NoContent = 204,

  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,

  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Error4xx = 400,
  Error5xx = 500,
}

enum ErrorCodes {
  Captcha = "CaptchaError",
  CardMergeBlocked = "cardMergeBlocked",
  IncompleteProfile = "incompleteProfile",
  UnprocessableEntity = "unprocessableEntity",
  AlreadyAccepted = "alreadyAccepted",
  EmailForbidden = "emailForbiddenToUse",
  ValidationError = "validationError",
}

/**
 * @swagger web-gateway: https://web-gateway.dev.ya.magnit.ru/docs/index.html
 * @swagger magnit-id: https://id.dev.ya.magnit.ru/docs/index.html
 */
const urls = {
  otp: {
    /**
     * Генерация и отправка OTP кода по номеру телефона
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDSendOTPV1
     */
    getCode: "/v1/auth/otp",
    /**
     * Проверка OTP и получение MagnitID Code кода для авторизации нового пользователя
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDCheckOTPV1
     */
    checkCode: "/v1/auth/otp/check",
  },
  auth: {
    /**
     * Авторизация
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDGenerateTokenV1
     */
    login: "/v1/auth/token",
    /**
     * Обновление JWT токена
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDRefreshTokenV1
     */
    refresh: "/v1/auth/token/refresh",
  },
  user: {
    /**
     * Получаем информацию для отрисовки QR-кода
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cards-v2/post_v2_totp
     */
    qrcode: "/v2/totp",
    /**
     * Получаем информацию по бонусам и магнитикам
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/balance/get_v2_user_bonus_balance
     */
    balance: "/v2/user/bonus-balance",
    /**
     * Получаем карточки пользователя
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cards-v2/get_v2_user_cards
     */
    cards: "/v2/user/cards",
    /**
     * Смерживаем вертуальную и физическую карты
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cards-v2/post_v3_user_card_merge
     */
    cardMerge: "/v3/user/card/merge",
    /**
     * Подтверждение e-mail
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDEmailConfirmV1
     */
    emailConfirmation: "/v1/profile/email-confirm",
    /**
     * Регистрация нового пользователя
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDRegisterProfileByMagnitIDCodeV1
     */
    register: "/v3/profile/register/magnit-id-code",
    /**
     * Получаем профиль пользователя или обноваляем (patch)
     * https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDGetProfileV3
     */
    profile: "/v1/profile",
    /**
     * Разрешения пользователя на получение sms, email, чеков на email.
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/profiles/get_v2_profile_permissions
     */
    permissions: "/v2/profile/permissions",
    /**
     * Получение/назначение любимых категорий пользователя.
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/favorite-categories
     */
    favoriteCategories: "/v1/favorite-categories",
    /**
     * Сохраняем push-токен и передаем состояние системного разрешения на показ уведомлений на сервер Cuscom
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cuscom/post_v1_cuscom_enroll_device
     */
    enrollDevice: "/v1/cuscom/enroll-device",
    /**
     * Деактивация профиля магнит
     * https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDDeactivateProfileV1
     */
    deactivate: "/v1/profile/deactivate",
  },
  wallets: {
    /**
     * Добавление карты в кошелёк
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/wallets/post_v1_wallets_apple_release_card
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/wallets/post_v1_wallets_google_release_card
     */
    android: "/v1/wallets/google/release-card",
    apple: "/v1/wallets/apple/release-card",
  },
  coupons: {
    /**
     * Получение всех купонов
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/coupons/get_v1_coupons
     */
    list: "/v1/coupons",
    /**
     * Получение категорий купонов
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/coupons/get_v1_coupons_categories
     */
    categories: "/v1/coupons/categories",
    /**
     * Отметить купон как любимый/нелюбимый
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/coupons/post_v1_coupons_favorite
     */
    favorite: "/v1/coupons/favorite",
    /**
     * Выполнение запроса для активации купона.
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/coupons/post_v1_coupons_activate
     */
    activate: "/v1/coupons/activate",
  },
  content: {
    /**
     * Данные для страницы cookie.
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cosmetic/get_v1_cosmetic_content_cookies
     */
    cookie: "/v1/cosmetic/content/cookies",

    /**
     * Контент для тела главной страницы (today). Содержит:
     * Данные для AdFox-слотов баннера-карусели на главной
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/mymagnit/get_v1_mymagnit_content_today_page
     */
    slots: "/v1/mymagnit/content/today-page",
  },

  ab: {
    /**
     * Получение экспериментов платформы A/B тестирования
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/ab-tests/post_v1_ab_experiments_and_features
     */
    experiments: "/v1/ab/experiments-and-features",
  },
  personalPromotions: {
    /**
     * Получение персональных предложений
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/personal-offers/get_v1_personal_offers_personal_promotions
     */
    list: "/v1/personal-offers/personal-promotions",

    /**
     * Получение деталей по персональному предложению
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/personal-offers/get_v1_personal_offers_personal_promotions__promotionCode_
     */
    detail: "/v1/personal-offers/personal-promotions/",

    /**
     * Активация персонального предложения
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/personal-offers/post_v1_personal_promotions_activate
     */
    activate: "/v1/personal-promotions/activate",
  },
  transactions: {
    /**
     * Получение списка транзакций
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/transactions/get_v1_user_transactions
     */
    list: "/v1/user/transactions",
    /**
     * Получение транзакции по ID
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/transactions/get_v1_user_transactions__transactionId_
     */
    item: "/v1/user/transactions",
  },
  support: {
    crafttalk: {
      /**
       * Получение даныых для инициализации чата CraftTalk
       */
      config: "/v1/support/crafttalk",
      /**
       * Триггер пуша эмитирующий событие от CraftTalk
       */
      push: "/v1/support/crafttalk/push/send",
    },
  },

  paymentMethods: {
    /**
     * Возвращает список доступных для пользователя платежных методов
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/payment-methods/get_v1_payment_methods
     */
    cards: "/v1/payment-methods",

    /**
     * Метод получения ссылки для привязки новой банковской карты
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/payment-methods/post_v1_payment_methods_cards_bind
     */
    bind: "/v1/payment-methods/cards/bind",
  },

  subscription: {
    /**
     * Метод получения данных по лендингу подписок
     * https://omni-web.k8s.dev.mos.corp/docs#/subscriptions-v2/subscriptionsV2GetWebV2SubscriptionLanding
     */
    landing: "/v2/subscription/landing",

    /**
     * Метод оформления подписки
     * https://omni-web.k8s.dev.mos.corp/docs#/subscriptions-v2/subscriptionsV2PostWebV2SubscriptionSubscribe
     */
    subscribe: "/v2/subscription/subscribe",

    /**
     * Возвращает статус подписки
     * https://omni-web.k8s.dev.mos.corp/docs#/subscriptions-v2/subscriptionsV2GetWebV2SubscriptionStatus
     */
    status: "/v2/subscription/status",
  },
};

export { urls, HttpCodes, ErrorCodes };
