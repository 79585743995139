<script setup lang="ts">
import VWrapperBase from "@magnit/core/src/components/VWrapper/VWrapper.vue";

const defaultIndent = {
  xs: "12px",
  s: "20px",
  m: "32px",
  ml: "32px",
  l: "32px",
  xl: "32px",
};

interface IVWrapperLocalProps {
  indent?: {
    xs?: string;
    s?: string;
    m?: string;
    ml?: string;
    l?: string;
    xl?: string;
  };
}

defineProps<IVWrapperLocalProps>();
</script>

<template>
  <VWrapperBase
    class="wrapper"
    :indent="{
      xs: indent?.xs || defaultIndent.xs,
      s: indent?.s || defaultIndent.s,
      m: indent?.m || defaultIndent.m,
      ml: indent?.ml || defaultIndent.ml,
      l: indent?.l || defaultIndent.l,
      xl: indent?.xl || defaultIndent.xl,
    }"
    :style="'--pl-wrapper-end: 600px;'"
  >
    <slot />
  </VWrapperBase>
</template>
