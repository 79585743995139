import { default as cookie_45policyWrqZjqr6gMMeta } from "/app/src/pages/cookie-policy.vue?macro=true";
import { default as _91couponId_93pAsBnwgDPFMeta } from "/app/src/pages/coupons/[couponId].vue?macro=true";
import { default as index8r44U4iA8XMeta } from "/app/src/pages/coupons/index.vue?macro=true";
import { default as delete_accountPkSpf8zeEUMeta } from "/app/src/pages/delete_account.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as personal_45promotionsgytXAicZ48Meta } from "/app/src/pages/personal-promotions.vue?macro=true";
import { default as favorite_45categoriestqWXpCwrWtMeta } from "/app/src/pages/profile/favorite-categories.vue?macro=true";
import { default as incompletepVbXbLiTn0Meta } from "/app/src/pages/profile/incomplete.vue?macro=true";
import { default as indexSJNeMkG0qzMeta } from "/app/src/pages/profile/index.vue?macro=true";
import { default as mplus9o9afICjpKMeta } from "/app/src/pages/profile/mplus.vue?macro=true";
import { default as changendsoKev7geMeta } from "/app/src/pages/profile/payment-methods/change.vue?macro=true";
import { default as deleteXKWxQTZ86dMeta } from "/app/src/pages/profile/payment-methods/delete.vue?macro=true";
import { default as payM46bMrrcY2Meta } from "/app/src/pages/profile/payment-methods/pay.vue?macro=true";
import { default as stickersBfe1RcMBHOMeta } from "/app/src/pages/profile/stickers.vue?macro=true";
import { default as _91id_93mshKDDNK9fMeta } from "/app/src/pages/profile/transactions/[id].vue?macro=true";
import { default as indexniqfSMMxZWMeta } from "/app/src/pages/profile/transactions/index.vue?macro=true";
import { default as pushtokennSUtf65U9RMeta } from "/app/src/pages/pushtoken.vue?macro=true";
import { default as qr4xFs4J9BZ6Meta } from "/app/src/pages/qr.vue?macro=true";
import { default as cardfAzS7zvV5UMeta } from "/app/src/pages/registration/card.vue?macro=true";
import { default as indexNbUvxHm6A3Meta } from "/app/src/pages/registration/index.vue?macro=true";
import { default as subscriptionML5heDAjOdMeta } from "/app/src/pages/subscription.vue?macro=true";
import { default as chatlTZTiY5WIPMeta } from "/app/src/pages/support/chat.vue?macro=true";
import { default as todayfuO7Y3P8Z2Meta } from "/app/src/pages/today.vue?macro=true";
export default [
  {
    name: "cookie-policy",
    path: "/cookie-policy",
    component: () => import("/app/src/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: "coupons-couponId",
    path: "/coupons/:couponId()",
    component: () => import("/app/src/pages/coupons/[couponId].vue").then(m => m.default || m)
  },
  {
    name: "coupons",
    path: "/coupons",
    component: () => import("/app/src/pages/coupons/index.vue").then(m => m.default || m)
  },
  {
    name: "delete_account",
    path: "/delete_account",
    component: () => import("/app/src/pages/delete_account.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login3hUYZUGxmUMeta || {},
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "personal-promotions",
    path: "/personal-promotions",
    component: () => import("/app/src/pages/personal-promotions.vue").then(m => m.default || m)
  },
  {
    name: "profile-favorite-categories",
    path: "/profile/favorite-categories",
    component: () => import("/app/src/pages/profile/favorite-categories.vue").then(m => m.default || m)
  },
  {
    name: "profile-incomplete",
    path: "/profile/incomplete",
    component: () => import("/app/src/pages/profile/incomplete.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/app/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-mplus",
    path: "/profile/mplus",
    component: () => import("/app/src/pages/profile/mplus.vue").then(m => m.default || m)
  },
  {
    name: "profile-payment-methods-change",
    path: "/profile/payment-methods/change",
    component: () => import("/app/src/pages/profile/payment-methods/change.vue").then(m => m.default || m)
  },
  {
    name: "profile-payment-methods-delete",
    path: "/profile/payment-methods/delete",
    component: () => import("/app/src/pages/profile/payment-methods/delete.vue").then(m => m.default || m)
  },
  {
    name: "profile-payment-methods-pay",
    path: "/profile/payment-methods/pay",
    component: () => import("/app/src/pages/profile/payment-methods/pay.vue").then(m => m.default || m)
  },
  {
    name: "profile-stickers",
    path: "/profile/stickers",
    component: () => import("/app/src/pages/profile/stickers.vue").then(m => m.default || m)
  },
  {
    name: "profile-transactions-id",
    path: "/profile/transactions/:id()",
    component: () => import("/app/src/pages/profile/transactions/[id].vue").then(m => m.default || m)
  },
  {
    name: "profile-transactions",
    path: "/profile/transactions",
    component: () => import("/app/src/pages/profile/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: "pushtoken",
    path: "/pushtoken",
    component: () => import("/app/src/pages/pushtoken.vue").then(m => m.default || m)
  },
  {
    name: "qr",
    path: "/qr",
    component: () => import("/app/src/pages/qr.vue").then(m => m.default || m)
  },
  {
    name: "registration-card",
    path: "/registration/card",
    component: () => import("/app/src/pages/registration/card.vue").then(m => m.default || m)
  },
  {
    name: "registration",
    path: "/registration",
    component: () => import("/app/src/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: "subscription",
    path: "/subscription",
    meta: subscriptionML5heDAjOdMeta || {},
    component: () => import("/app/src/pages/subscription.vue").then(m => m.default || m)
  },
  {
    name: "support-chat",
    path: "/support/chat",
    meta: chatlTZTiY5WIPMeta || {},
    component: () => import("/app/src/pages/support/chat.vue").then(m => m.default || m)
  },
  {
    name: "today",
    path: "/today",
    component: () => import("/app/src/pages/today.vue").then(m => m.default || m)
  }
]