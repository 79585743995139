<script setup lang="ts">
import VLoader from "@magnit/core/src/components/VLoader/VLoader.vue";

interface IBlockLoaderProps {
  absolute?: boolean;
  page?: boolean;
  show?: boolean;
  overlay?: boolean;
  divWrapper?: boolean;
}

const props = defineProps<IBlockLoaderProps>();
defineOptions({ inheritAttrs: false });

const wrapper = computed(() => (props.divWrapper ? "div" : resolveComponent("ClientOnly")));
</script>

<template>
  <component :is="wrapper">
    <teleport to="body" :disabled="!page">
      <transition name="fade">
        <div
          v-if="show"
          v-bind="$attrs"
          class="block-loader"
          :class="{
            'block-loader_absolute': absolute,
            'block-loader_fixed': page,
            'block-loader_overlay': overlay || page,
          }"
        >
          <div>
            <VLoader size="l" title="Загрузка..." />
          </div>
        </div>
      </transition>
    </teleport>
  </component>
</template>

<style lang="postcss" scoped>
.block-loader {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: calc(var(--pl-z-index-toast) + 1);

  &_absolute {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  &_fixed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }

  &_overlay {
    background: var(--pl-overlay-background-loading);
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
