import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { formatToUid } from "~/utils/etc";
import { storage } from "~/utils/consts";

export default defineNuxtPlugin(async () => {
  const uidCookie = useCookie(storage.uniqDeviceID);

  if (import.meta.server || uidCookie.value) return;

  const fp = await FingerprintJS.load();
  const { visitorId } = await fp.get();

  uidCookie.value = formatToUid(visitorId).toUpperCase();
});
