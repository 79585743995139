<script setup lang="ts">
import { useRouter } from "vue-router";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VWrapperLocal from "~/components/VWrapperLocal.vue";
import VPageBack from "~/components/VPageBack.vue";
import { Routes } from "~/utils/routes";
import useAnalytics from "~/composables/useAnalytics";

const { send } = useAnalytics();

const router = useRouter();
onMounted(() => {
  const visitParams = {
    NotFoundURL: { [document.location.href]: { Реферер: document.referrer } },
  };
  send("Page:Error404:View", visitParams);
});
</script>

<template>
  <VWrapperLocal class="page-404">
    <VPageBack />

    <div class="page-404__centered">
      <div class="page-404__img">
        <img src="~/assets/images/404-bg.png">
      </div>

      <VText class="page-404__text" tag="div">
        Здесь ничего не нашлось, а вот на главной странице много полезной информации
      </VText>

      <VButton class="page-404__button_desktop" @click="router.push(Routes.Main)">
        На главную
      </VButton>
    </div>

    <VButton class="page-404__button" @click="router.push(Routes.Main)">
      На главную
    </VButton>
  </VWrapperLocal>
</template>

<style lang="postcss" scoped>
.page-404 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;

  &__centered {
    text-align: center;
  }

  &__img {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    & img {
      display: block;
      max-width: 277px;
    }
  }

  &__button {
    margin-bottom: 16px;
  }

  &__button_desktop {
    display: none;
  }
}

@media (--pl-viewport-from-l) {
  .page-404 {
    justify-content: center;

    &__centered {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 100%;
    }

    &__text {
      margin-bottom: 32px;
    }

    &__button {
      display: none;
    }

    &__button_desktop {
      display: block;
      max-width: 180px;
    }
  }
}
</style>
