import { render, h } from "vue";
import VBlockLoader from "~/components/VBlockLoader.vue";

const parentElement = typeof document !== "undefined" && document.createElement("div");

const destroy = () => {
  if (!parentElement) {
    return;
  }
  render(null, parentElement);
};

const mount = () => {
  if (!parentElement) {
    return;
  }
  const vNode = h(VBlockLoader, { page: true, show: true, divWrapper: true });
  destroy();
  render(vNode, parentElement);
};

const usePageLoader = () => {
  let timerPromise = Promise.resolve();
  return {
    show() {
      timerPromise = new Promise((resolve) => {
        setTimeout(resolve, 500);
      });

      mount();
    },
    hide() {
      timerPromise.then(() => {
        destroy();
      });
    },
  };
};

export default usePageLoader;
