import { defineStore } from "pinia";
import { reactive, ref } from "vue";
import { useLocalStorage } from "@vueuse/core";
import { useTransport } from "~/composables/useTransport";
import { urls } from "~/api/config";
import { useUserStore } from "~/store/user";

type IStoreStatus = "initial" | "pending" | "success" | "error";

interface IAbStatus {
  experiments: IStoreStatus;
}

interface IAbExperiments {
  activeExperimentsGroupIds: number[];
  features: {
    featureName: string;
    groupName: string;
    groupValue: string;
  }[];
  ttlSeconds: number;
}

export const useAbStore = defineStore("ab", () => {
  const userStore = useUserStore();

  const status = reactive<IAbStatus>({
    experiments: "initial",
  });
  const experiments = ref<IAbExperiments | null>(null);

  async function getExperiments() {
    status.experiments = "pending";

    const savedUserId = useLocalStorage<{
      uuid: string;
    }>(storage.uuid, {
      uuid: "",
    });

    const { data, error } = await useTransport<IAbExperiments>(
      urls.ab.experiments,
      {
        method: "POST",
        permissions: {
          jwt: true,
        },
        body: {
          "split-id": savedUserId.value.uuid || userStore.profile.userId,
          "namespace": "myMagnit_web",
        },
      },
    );

    if (data.value) {
      experiments.value = data.value;
      status.experiments = "success";
    } else if (error.value) {
      status.experiments = "error";
    }
  }

  return {
    getExperiments,
    experiments,
    status,
  };
});
